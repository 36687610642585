<template>
  <q-drawer
    v-model="ui.quasar.sides.left"
    :breakpoint="ui.quasar.breakpoints.left"
    :mini="ui.quasar.mini.mode"
    :mini-width="ui.quasar.mini.width"
    elevated
    show-if-above
    side="left"
    animated
  >
    <q-scroll-area class="fit">
      <!-- Mobility Nearby buttons -->
      <div class="q-px-lg q-pt-lg mobility">
        <strong>{{ $t('mobility_nearby') }}</strong>
        <div class="row q-my-md justify-center">
          <div
            v-for="(item, key) in mobilityAndBookablesCombined"
            :key="`mobilityNearbyButton${key}`"
            class="col-3 q-mb-md text-center nearby-item"
            @click="isTypeEnabled(item) ? isPolicyEnabled(item) ? searchNearby(item) : showWarning(item, 'nearby') : null"
          >
            <q-tooltip v-if="!isTypeEnabled(item)" :target="`#mobilityNearbyButton${key}`">
              {{ $t('no_permission_to_search') }}
            </q-tooltip>
            <q-btn
              :id="`mobilityNearbyButton${key}`"
              unelevated round
              class="q-mb-sm nearby-icon"
            >
              <m-travel-icon :type="item" color="white" size="48px" :bg-color="isTypeEnabled(item) ? getTravelContentHex(item) : '#757575'" circle />
            </q-btn>
            <div class="text-subtitle2">
              {{ $tc(`content_type.${item}`) }}
            </div>
          </div>
        </div>
      </div>

      <q-dialog
        v-model="isWarningVisible"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <q-card class="text-center" square>
          <q-card-section class="full-height">
            <p v-html="$t('transport_policy_disabled_detail')" />
            <p v-html="$t('transport_policy_disabled_continue')" />
          </q-card-section>
          <q-card-actions align="center">
            <q-btn v-close-popup color="primary" unelevated :label="$t('back')" />
            <q-btn v-if="actionType === 'book'" v-close-popup color="primary" outline :label="$t('continue')" @click.stop="$router.push({ name: getTravelContentRoute(contentType, 'search') })" />
            <q-btn v-else-if="actionType === 'nearby'" v-close-popup color="primary" outline :label="$t('continue')" @click.stop="searchNearby(contentType)" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Smartsearch mini form -->
      <div class="smartsearch bg-primary">
        <div class="q-pa-md">
          <div class="row">
            <div class="col column justify-around">
              <q-icon name="fas fa-route" class="fa-2x" />
            </div>
            <div class="col-10">
              <p class="text-weight-medium">
                {{ $t('smartsearch.smartsearch') }}
              </p>
              <p class="text-subtitle1">
                {{ $t('smartsearch.a_to_b') }}
              </p>
              <p class="text-subtitle2">
                {{ $t('smartsearch.plan_your_journey') }}
              </p>
            </div>
          </div>
          <div class="row q-col-gutter-sm q-pt-md">
            <div class="col column">
              <m-select-query
                v-model="smartsearchParams.origin"
                :placeholder="$t('choose_origin')"
                dense
                outlined
                color="primary-tint"
                bg-color="white"
                :query="smartsearchAutocomplete"
                smart-search
                @focus="onSmartSearchFocus('origin')"
              />
              <m-select-query
                v-model="smartsearchParams.destination"
                :placeholder="$t('choose_destination')"
                dense
                outlined
                color="primary-tint"
                bg-color="white"
                :query="smartsearchAutocomplete"
                smart-search
                @focus="onSmartSearchFocus('destination')"
              />
            </div>
            <div class="col-1 column justify-around q-pa-xs">
              <q-btn icon="fas fa-sort-alt" flat dense @click="smartsearchSwapLocations" />
            </div>
          </div>
        </div>
        <div class="smartsearchFooter bg-grey-3 q-pb-sm q-px-md" :class="{ 'expanded': smartsearchAdvancedMode }">
          <div v-if="smartsearchAdvancedMode" class="row">
            <div class="col-12">
              <m-date-field
                v-model="smartsearchParams.depart"
                :min="minDate"
                :label="this.$t('date.travel')"
              />
            </div>
            <div class="col-12">
              <m-time-field
                v-model="smartsearchParams.depart"
                :min="minDate"
                :label="$t('time.from')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col q-pt-sm">
              <q-btn
                v-if="!smartsearchAdvancedMode"
                :label="smartsearchLeavingLabel"
                dense
                color="primary"
                flat
                no-caps
                icon="far fa-clock"
                @click="smartsearchAdvancedMode = true"
              />
            </div>
            <div class="col-4">
              <m-cta-button
                :label="$t('smartsearch.leave_now')"
                :disabled="!validSmartsearch"
                base-size="12px"
                @ctaClick="$store.dispatch('smartsearch/initialQuery'); smartsearchAdvancedMode = false"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Book Now buttons -->
      <div class="q-pt-lg bookNow">
        <strong>{{ $t('book_now') }}</strong>
        <p class="text-subtitle2">
          {{ $t('know_what_you_need') }} <strong>{{ $t('book_below') }}:</strong>
        </p>
        <div class="row q-col-gutter-sm q-py-sm center-last">
          <div v-for="(bookable, key) in bookables" :key="`bookable-${key}`" class="stretch-odd">
            <q-btn
              class="full-width"
              padding="0px"
              :class="`org-enabled-${isPolicyEnabled(bookable)} ${(key % 2) ? 'even' : 'odd'}`"
              :align="((key % 2 === 0) && (key === (bookables.length - 1))) ? 'center' : 'left'"
              color="primary"
              no-caps
              unelevated
              @click="isPolicyEnabled(bookable) ? $router.push({ name: getTravelContentRoute(bookable, 'search') }) : showWarning(bookable, 'book')"
            >
              <div class="bookTypeButton">
                <m-travel-icon :type="bookable" color="white" bg-color="var(--q-color-primary)" size="32px" />
                <div style="margin-top: -2px;">
                  {{ $tc(`content_type.${bookable}`) }}
                </div>
              </div>
              <q-badge v-if="!isPolicyEnabled(bookable)" rounded floating color="orange" />
            </q-btn>
          </div>
        </div>
      </div>

      <!-- App download buttons -->
      <div v-if="showDownloadApp" class="mobileApp q-px-lg q-mb-sm">
        <p class="q-pt-sm">
          {{ $t('download_app', { partner: authPartner.name }) }}:
        </p>
        <div class="row">
          <div v-if="androidAppLink" class="col">
            <q-img class="cursor-pointer" :src="require('assets/download-play-store.png')" @click="externalUrl(androidAppLink)" />
          </div>
          <div v-if="iosAppLink" class="col">
            <q-img class="cursor-pointer" :src="require('assets/download-app-store.png')" @click="externalUrl(iosAppLink)" />
          </div>
        </div>
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { mapGetters } from 'vuex'
import { query as placeQuery } from 'api/places'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { MSelectQuery, MTimeField, MDateField, MTravelIcon, MCtaButton } from 'components/'
const { addToDate, roundMinutesUp, luxonFormatDate } = date

export default {
  name: 'LayoutBasicSidebar',
  components: { MSelectQuery, MTimeField, MDateField, MTravelIcon, MCtaButton },
  mixins: [ authentication, travelContents ],
  data () {
    return {
      smartsearchAdvancedMode: false,
      tooltip: {
        offset: [-64, 0],
        anchor: 'top left',
        self: 'top left',
        delay: 300
      },
      smartsearchLeavingLabel: this.$t('smartsearch.choose_a_time'),
      policyDisabledCount: 0,
      isWarningVisible: false,
      contentType: null,
      actionType: null
    }
  },
  computed: {
    ...mapGetters({
      ui: 'ui',
      latLng: 'geolocate/latLng',
      smartsearchParams: 'smartsearch/parameters',
      smartsearchLoading: 'smartsearch/loading',
      user: 'userLookup',
      authPartner: 'partner',
      organisation: 'organisation',
      travelPreferences: 'preferences'
    }),
    validSmartsearch () {
      return this.smartsearchParams.origin && this.smartsearchParams.destination
    },
    minDate () {
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 30 }), 15)
    },
    mobilityAndBookablesCombined () {
      // Return a compound list of mobility and bookables, sans any bookables that are intentionally excluded; e.g. hotels
      let arr = [...this.authPartner.mobility, ...this.authPartner.bookables]
        .filter(item => !this.travelContentExcluded(item, ['desktop', 'mobilityNearby']))
      // Temp removal of coach if exists
      arr = arr.filter(b => b !== 'coach')

      return arr
    },
    bookables () {
      let arr = [...this.authPartner.bookables]

      return arr.filter(b => this.isTypeEnabled(b))
    },
    androidAppLink () {
      return this.authPartner?.appLinks?.android
    },
    iosAppLink () {
      return this.authPartner?.appLinks?.ios
    },
    showDownloadApp () {
      return this.androidAppLink || this.iosAppLink
    }
  },
  watch: {
    'smartsearchParams.depart': {
      deep: true,
      handler (val) {
        this.smartsearchLeavingLabel = luxonFormatDate(val, 'dd LLL HH:mm')
      }
    },
    latLng: {
      deep: true,
      handler (val) {
        this.smartsearchParams.origin = {
          icon: 'my_location',
          label: this.$t('current_location'),
          value: `${val.latitude},${val.longitude}`
        }
      }
    },
    smartsearchLoading (value, old) {
      if (value === true) {
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: this.authPartner.slug,
          spinnerColor: this.authPartner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        if (this.errors) {
          this.$q.dialog({
            title: this.$t('error.search_unsuccessful'),
            message: this.$t('error.try_later'),
            color: 'primary',
            ok: true,
            cancel: this.$t('leave_feedback')
          })
            .onCancel(() => {
              var win = window.open('https://mobilleo.com/resources/support/', '_blank')
              win.focus()
            })
        } else {
          this.$router.push({ name: 'smart-search-results' })
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch('smartsearch/setRequester', this.user)
    this.$store.dispatch('smartsearch/setTraveller', this.user)
  },
  methods: {
    externalUrl (url) {
      if (url) window.open(url, '_blank')
    },
    smartsearchSwapLocations () {
      [this.smartsearchParams.destination, this.smartsearchParams.origin] = [
        this.smartsearchParams.origin,
        this.smartsearchParams.destination
      ]
    },
    smartsearchAutocomplete (terms) {
      return placeQuery(terms)
        .then(response => {
          return response.data.map(result => {
            return {
              label: result.description,
              value: result.place_id,
              icon: 'place'
            }
          })
        })
    },
    searchNearby (type) {
      if (['drt'].includes(type)) {
        this.$router.push({ name: 'ondemand-drt' })
        return
      }
      const pref = this.getTravelPreferenceName(type)

      if (this.$route.name !== 'dashboard') this.$router.push({ name: 'dashboard' })
      this.$store.dispatch('discovery/searchNearby', { searchType: pref, fresh: true })
    },
    onSmartSearchFocus (name) {
      if (this.smartsearchParams[name]?.label?.toLowerCase() === 'current location') this.smartsearchParams[name] = null
    },
    showWarning (type, action) {
      if (!this.isWarningVisible) {
        this.isWarningVisible = true
        this.contentType = type
        this.actionType = action
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'home-search') {
      this.$root.$children[0].$refs.layout.$refs.sheet.scrollToBottom()
      setTimeout(next, 100)
    } else {
      next()
    }
  }
}
</script>

<style lang="stylus" scoped>
.smartsearch
  .text-subtitle1
    font-size .75em
    line-height 1em
  .text-subtitle2
    font-size .78em
    line-height 1.5em

.nearby-icon
  color #fff

.nearby-icon-contrast
  color #222
.nearby-item
  .text-subtitle2
    font-size .75em
.bookTypeButton
  width 100%
  display grid
  grid-template-columns 32px auto
  justify-items center
  align-items center
  padding 4px 6px
  font-size 0.9em

.bookNow
  padding-inline 12px
  .text-subtitle2
    font-weight 400
    font-size .75em
    line-height 1.35em
.mobileApp
  position absolute
  left 0
  right 0
  font-size .75em
  text-align center
.smartsearchFooter
  height 48px
  transition height .5s
  overflow hidden
  &.expanded
    height 176px
.text-small
  font-size 0.8rem

.stretch-odd
  min-width 50%
  flex-grow 1
  button
    border-radius 8px
</style>
